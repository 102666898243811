$icon-base-path: '/assets/icons' !default;
$font: 'Proxima Nova';

// color palette;
$apple-green: #7ed321;
$black: #000000;
$blue-bright: #24d8d8;
$blue-dark: #7be8ff;
$blue-darkest: #01a9b8;
$blue-light: #baeef4;
$blue-lighter: #e8ffff;
$blue: #5dcbd5;
$brownish-grey: #666666;
$brow-grey: #9b9b9b;
$cloudy-blue: #babdd4;
$cool-grey: #8b999f;
$dark-blue-grey: #192b4c;
$dark-blue: #1a2164;
$dark-blue-2: #203058;
$dark-grey-blue: #2c426a;
$darker-blue: #16263f;
$duck-egg-blue-two: #baeef4;
$duck-egg-blue: #f5fbfd;
$dull-orange: #d6683a;
$grape: #66324a;
$green-add: #c5f785;
$green-dark: #659d16;
$green-light: #96ca54;
$green: #7ed321;
$grey: #5a595a;
$greyish-brown: #4a4a4a;
$ice-blue: #e5ffff;
$light-navy: #13439d;
$light-cyan: #b2ebf2;
$maize: #ffc94f;
$mustard-yellow: #f2ca75;
$nest-blue: #64d4d6;
$nice-blue: #1297b4;
$orange: #ff9c00;
$pale-grey-three: #e4eaee;
$pale-grey-two: #e7edee;
$pale-grey: #eff4f5;
$pink-lighter: #ffe2e4;
$pinky-red: #ff1744;
$pistachio: #c5f785;
$red-bright: #ff1a4d;
$red-brown: #9d2713;
$red-dark: #cb021f;
$red: #ff6c7a;
$salmon-pink: #ff6c7a;
$sap-green: #659d16;
$silver: #b2bfc4;
$slate-grey-two: #606480;
$slate-grey: #606480;
$turquoise-blue: #04b6d0;
$warm-grey-10: rgba(151, 151, 151, 0.1);
$white-two: #f5f5f5;
$white: #ffffff;
$yellow-dark: #cc6d00;
$yellow: #f5a623;
$zumthor-grey: #ced1d2;
$silver-light: #d8d8d8;
$red-light: #ff616f;
$green-darker: #3c6b6f;
$grey-light: #f3f3f3;
$purple: #ce93d8;
$red-lighter: #ff415f;
$green-lighter: #e7f2d9;
$green-bright: #8bc34a;
$blue-black: #1e3153;
$purple-light: #d49edd;
$purple-dark: #9c64a6;

// @todo clarify guidelines & tokens with design dept

// #region global
$neutral-color: $grey;
// #endregion global

// #region input
$input-active-color: $turquoise-blue;
$input-error-color: $pinky-red;
// #endregion input

// #region Tip
$error-tip-color: $pinky-red;
$tip-font-size: 12px;
// #endregion Tip

// #region Separators
$line-separator-color: $dark-blue-grey;
$line-separator-color-light: $pale-grey-three;
// #endregion Separators

// #region TagLabel
$tag-label-foreground-color: $dark-blue-grey;
$tag-label-background-color: $white;
// #endregion TagLabel

$font-size-normal: 12px;
$font-size-larger: 15px;

$text-color-alert: $pinky-red;
$text-color-active: $turquoise-blue;
$side-bar-background-color-neutral: $pale-grey;

$section-title-color-dark: $dark-blue-grey;

$theme-background: $turquoise-blue;
$theme-foreground: $white;
// forms
$form-spacing: 35px;

$agency-color: $purple-dark;
