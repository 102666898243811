::-webkit-scrollbar-track {
  box-shadow: none;
  -webkit-box-shadow: none;
}

::-webkit-scrollbar-thumb {
  -webkit-box-shadow: none;
  box-shadow: none;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: $blue-darkest;
  box-shadow: 0;
  -webkit-box-shadow: 0;
  height: 54px;
}
