@use 'sass:math';
@function remove($list, $value, $recursive: false) {
  $result: ();

  @for $i from 1 through length($list) {
    @if type-of(nth($list, $i)) == list and $recursive {
      $result: append($result, remove(nth($list, $i), $value, $recursive));
    } @else if nth($list, $i) != $value {
      $result: append($result, nth($list, $i));
    }
  }

  @return $result;
}
@function to-string($list, $glue: '', $is-nested: false) {
  $result: null;
  $list: remove($list, null);
  @for $i from 1 through length($list) {
    $e: nth($list, $i);

    @if type-of($e) == list {
      $result: $result#{to-string($e, $glue, true)};
    } @else {
      $result: if($e and $i != length($list) or $is-nested, $result#{$e}#{$glue}, $result#{$e});
    }
  }

  @return $result;
}
@function icon-url($icon, $state: null, $type: 'svg') {
  @return $icon-base-path + '/' + to-string(($icon, $state), '-') + '.' + $type;
}
@mixin icon($icon, $state: null, $type: 'svg') {
  background-image: url(icon-url($icon, $state, $type));
  background-repeat: no-repeat;
  background-size: cover;
}

@mixin icon-contain($icon, $state: null, $type: 'svg') {
  @include icon($icon, $state, $type);
  background-size: contain;
  background-repeat: no-repeat;
}

@mixin no-icon() {
  content: none;
}
@mixin font-family() {
  font-family: $font;
}
@mixin font-family-bold() {
  font-family: $font;
  font-weight: bold;
}
@mixin font-family-italic() {
  font-family: $font;
  font-style: italic;
}

@mixin font-family-semi-bold() {
  font-family: $font;
  font-weight: 600;
}

@mixin separator-line() {
  height: 0px;
  border-bottom: solid 1px $line-separator-color;
}

@mixin bold-family-on-b() {
  b {
    @include font-family-bold;
  }
}

@mixin sync_failed($height, $width) {
  @include icon('bell');
  position: absolute;
  height: $height;
  width: $width;
  top: 50%;
  margin-top: -1 * math.div($height, 2);
  margin-left: -1 * ($width + math.div($width, 2));
  content: '';
}

@mixin ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
